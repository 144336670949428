import React from "react"

import SvgIcon from '@mui/material/SvgIcon';

const PageRight = props => (
    <SvgIcon {...props} viewBox="0 0 20 20">
        <path d="M4.1665 9.99984H15.8332M15.8332 9.99984L9.99984 4.1665M15.8332 9.99984L9.99984 15.8332" stroke="#282421" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
)

export default PageRight
