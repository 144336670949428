import * as React from 'react'
import { graphql, Link } from 'gatsby'

import Pagination from '@mui/material/Pagination';
import { Container, Grid, PaginationItem } from '@mui/material';
import Breadcrumbs from '../components/Breadcrumbs';
import RichText from '../components/RichText';
import ArticleCard from '../components/cards/ArticleCard';
import { linkResolver } from '../utils/linkResolver';
import PageLeft from '../components/icons/PageLeft';
import PageRight from '../components/icons/PageRight';
import Seo from '../components/Seo';
import buildMetaData from '../services/buildMetaData';
import buildOgpTags from '../services/buildOgpTags';
import Content from '../components/Content';
import addToSchema from '../services/addToSchema';

const ArticlesPageTemplate = ({ data, pageContext }) => {
  const page = data.page || {}

  const articles = data?.articles?.edges.map(({ node }) => node) || {}

  return <>
    <br />
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs breadcrumbs={pageContext.breadcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <RichText
            sx={{
              textAlign: 'center'
            }}
            content={page.data.title.richText}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {articles.map(article => <Grid item xs={12} sm={4} key={`article-${article.id}`}>
              <ArticleCard article={article} />
            </Grid>)}
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{
          display: 'flex',
          justifyContent: 'center'
        }}>
          <Pagination
            count={pageContext.numPages}
            page={pageContext.currentPage}
            renderItem={(item) => {
              return <PaginationItem
                slots={{ previous: PageLeft, next: PageRight }}
                sx={{
                  borderRadius: '6px',
                  WebkitMaskImage: '-webkit-radial-gradient(white, black);',
                  '&.Mui-selected': {
                    backgroundColor: '#FFEAEA',
                    color: 'primary.main'
                  }
                }}
                component={Link}
                to={`${linkResolver(page)}${(item.page > 1 && item.page <= pageContext.numPages) ? item.page + '/' : ''}`}

                {...item}
              />
            }
            } />
        </Grid>

      </Grid>
    </Container>
    <Content doc={page} />
  </>

}

export const Head = ({ data, pageContext }) => {
  const page = data.page || {}

  const meta = buildMetaData(page)
  const ogp = buildOgpTags(page, pageContext)

  const path = pageContext.currentPage > 1 ? `${pageContext.url}${pageContext.currentPage}/` : pageContext.url
  let pathNext = null
  let pathPrev = null

  if (pageContext.currentPage === 1) {
    pathNext = `${pageContext.url}${pageContext.currentPage + 1}/`
  } else if (pageContext.currentPage === 2) {
    pathNext = `${pageContext.url}${pageContext.currentPage + 1}/`
    pathPrev = `${pageContext.url}`
  } else {
    pathNext = `${pageContext.url}${pageContext.currentPage + 1}/`
    pathPrev = `${pageContext.url}${pageContext.currentPage - 1}/`
  }

  if (pageContext.numPages === pageContext.currentPage) {
    pathNext = null
  }

  let schema = page.data?.schema_json?.text || ""

  // FAQ
  if (page.data?.faq?.length) {
    schema = addToSchema(schema, {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": page.data.faq.map(({ question: faq }) => (
        {
          "@type": "Question",
          "name": faq.document.data.question.text.replace(/(\r\n|\n|\r)/gm, ""),
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.document.data.answer.text.replace(/(\r\n|\n|\r)/gm, "")
          }
        }
      ))
    })
  }

  return <Seo schema={schema} ogp={ogp} meta={meta} breadcrumbs={pageContext.breadcrumbs} path={path} pathNext={pathNext} pathPrev={pathPrev} />
}

export const query = graphql`
  query articlesPageQuery(
    $lang: String, 
    $skip: Int!
    $limit: Int!
  ) {
    page: prismicArticlesPage(lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      uid
      url
      type
      data {           
        meta_title
        meta_description
        meta_keywords
        ogp_tags {
          content
          property
        }
        schema_json {
          text
        }
        faq {
          question {
            document {
              ... on PrismicFaq {
                id
                data {
                  answer {
                    text
                  }
                  question {
                    text
                  }
                }
              }
            }
          }
        }
        title {
          richText      
        }

        body {

          ... on PrismicArticlesPageDataBodyIntro {
            id
            slice_type
            primary {
              intro_headline {
                richText
              }
              intro_box_1_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
              intro_box_2_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
              intro_description {
                richText
              }
              intro_box_3_text {
                richText
              }
            }
          }




          ... on PrismicArticlesPageDataBodyCarousel {
            id
            slice_type
            items {
              slide_image {
                gatsbyImageData(width: 400)
                alt
              }
            }
            primary {
              content {
                text
                richText
              }
            }
          }


          ... on PrismicArticlesPageDataBodyAboutUs {
            id
            slice_type
            primary {
              about_us_title {
                richText
              }
              about_us_description {
                richText
              }
              about_us_upper_left_text
              about_us_upper_right_text
              about_us_lower_left_text
              about_us_lower_right_text
            }
          }

        }
      }
    }


    articles: allPrismicArticle(
      skip: $skip
      limit: $limit
      sort: {order: DESC, fields: first_publication_date}
      filter: {lang: {eq: $lang}}
    ) {
      edges {
        node {
          lang
          uid
          id
          type
          data {
            title {
              text
            }
            short_description {
              text
            }            
            author {
              document {
                ... on PrismicTeamMember {
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
            image {
              alt
              gatsbyImageData(width: 400)
            }
            body {
              ... on PrismicArticleDataBodySimpleArticleText {
                id
                slice_type
                primary {
                  text {
                    richText
                    text
                  }
                }
              }
              ... on PrismicArticleDataBodyWrappedImage {
                id
                slice_type
                primary {
                  wrapped_image_image_size
                  wrapped_image_text_size
                  wrapped_image_text_background_full
                  image_position
                  wrapped_image {
                    alt
                    gatsbyImageData
                  }
                  wrapped_text {
                    richText
                    text
                  }
                }
              }



              
            }
          }
        }
      }
    }
    
  }
`

export default ArticlesPageTemplate