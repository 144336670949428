import React from "react"

import SvgIcon from '@mui/material/SvgIcon';

const PageLeft = props => (
    <SvgIcon {...props} viewBox="0 0 20 20">
        <path d="M15.8335 9.99984H4.16683M4.16683 9.99984L10.0002 4.1665M4.16683 9.99984L10.0002 15.8332" stroke="#282421" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
)

export default PageLeft
